import { autoinject, bindable, observable } from 'aurelia-framework';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { ValidationController, ValidationRules, Validator } from 'aurelia-validation';
import { I18N } from 'aurelia-i18n';

type Model = {
  Id?: number;
  ProductId?: number;
  SpecialProductTypeId?: number;
};

export type SpecialProductWashProductModel = { Id?: number; ProductId: number; SpecialProductTypeId: number };

@autoinject
export class SpecialProductWashProductCreateEdit {
  @bindable
  @observable
  model: Model;
  targetModel: Model = { ProductId: undefined, SpecialProductTypeId: undefined };

  @bindable
  protected specialProductTypes: Models.SpecialProductType[] = [];

  modelChanged(model: Model) {
    this.targetModel = JSON.parse(JSON.stringify(model));
    this.form.Id = this.targetModel?.Id;
    this.form.ProductId = this.targetModel.ProductId;
    this.form.SpecialProductTypeId = this.targetModel.SpecialProductTypeId;
  }

  @bindable onSave: (data: { data: SpecialProductWashProductModel }) => void;
  @bindable onCanceled: () => void;

  @bindable
  visible = false;

  protected form: Model = {
    ProductId: undefined,
    SpecialProductTypeId: undefined,
  };

  constructor(
    private errorService: ErrorService,
    private validationController: ValidationController,
    private validator: Validator,
    private t: I18N
  ) {}

  protected applyValidationRules() {
    ValidationRules.ensure('SpecialProductTypeId')
      .required()
      .withMessage(this.t.tr('general.requiredField'))
      .min(1)
      .withMessage(this.t.tr('general.requiredField'))
      .ensure('ProductId')
      .required()
      .withMessage(this.t.tr('general.requiredField'))
      .min(1)
      .withMessage(this.t.tr('general.requiredField'))
      .on(this.form);
  }

  attached() {
    this.applyValidationRules();
  }

  private async validate() {
    this.applyValidationRules();
    await this.validator.validateObject(this.form);
    return (await this.validationController.validate({ object: this.form })).valid;
  }

  async save() {
    try {
      if (!(await this.validate())) return;
      this.onSave?.({ data: this.form as SpecialProductWashProductModel });
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  cancel() {
    this.onCanceled?.();
  }

  protected async setSpecialProductTypeId(id: number | string) {
    this.form.SpecialProductTypeId = +id as number;
    await this.validate();
  }

  protected async setProductId(data: { value: number | string }) {
    this.form.ProductId = +data.value as number;
    await this.validate();
  }
}
