import { autoinject, bindable, observable } from 'aurelia-framework';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { ValidationController, ValidationRules, Validator } from 'aurelia-validation';
import { I18N } from 'aurelia-i18n';
import { ServiceInvoiceWashWasteNetTypeProduct } from 'models';

@autoinject
export class ServiceInvoiceWashWasteNettypeProductCreateEdit {
  @bindable
  @observable
  model: ServiceInvoiceWashWasteNetTypeProduct | undefined = undefined;

  targetModel: ServiceInvoiceWashWasteNetTypeProduct = {
    NetTypeId: undefined,
    Id: undefined,
    WashProductId: undefined,
    WasteProductId: undefined,
  };

  @bindable
  protected handleFor: 'wash' | 'waste';

  @bindable
  protected netTypes: Models.NetType[] = [];

  modelChanged(model: ServiceInvoiceWashWasteNetTypeProduct) {
    this.targetModel = JSON.parse(JSON.stringify(model));
    this.form.Id = this.targetModel?.Id;
    this.form.NetTypeId = this.targetModel.NetTypeId;
    this.form.WashProductId = this.targetModel.WashProductId;
    this.form.WasteProductId = this.targetModel.WasteProductId;
  }

  @bindable onSave: (data: { data: ServiceInvoiceWashWasteNetTypeProduct }) => void;
  @bindable onCanceled: () => void;

  @bindable
  visible = false;

  protected form: ServiceInvoiceWashWasteNetTypeProduct = {
    WashProductId: undefined,
    WasteProductId: undefined,
    NetTypeId: undefined,
    Id: undefined,
  };

  constructor(
    private errorService: ErrorService,
    private validationController: ValidationController,
    private validator: Validator,
    private t: I18N
  ) {}

  protected applyValidationRules() {
    ValidationRules.ensure(this.handleFor === 'wash' ? 'WashProductId' : 'WasteProductId')
      .required()
      .withMessage(this.t.tr('general.requiredField'))
      .min(1)
      .withMessage(this.t.tr('general.requiredField'))
      .ensure('NetTypeId')
      .required()
      .withMessage(this.t.tr('general.requiredField'))
      .min(1)
      .withMessage(this.t.tr('general.requiredField'))
      .on(this.form);
  }

  attached() {
    this.applyValidationRules();
  }

  private async validate() {
    this.applyValidationRules();
    await this.validator.validateObject(this.form);
    return (await this.validationController.validate({ object: this.form })).valid;
  }

  async save() {
    try {
      if (!(await this.validate())) return;
      this.onSave?.({ data: this.form });
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  cancel() {
    this.onCanceled?.();
  }

  protected async setNetTypeId(id: number | string) {
    this.form.NetTypeId = +id as number;
    await this.validate();
  }

  protected async setProductId(data: { value: number | string }, type: 'wash' | 'waste') {
    if (type === 'wash') this.form.WashProductId = +data.value as number;
    if (type === 'waste') this.form.WasteProductId = +data.value as number;
    await this.validate();
  }
}
