import { autoinject, bindable, observable } from 'aurelia-framework';
import { ErrorService } from 'services/error-service';
import { ValidationController, ValidationRules, Validator } from 'aurelia-validation';
import { I18N } from 'aurelia-i18n';

type Model = {
  Circumference: number;
  [key: number]: {
    ProductId: number;
    Id?: number;
  };
};

type data = { data: MatrixSaveModel[] };

export type MatrixSaveModel = { ProductId: number; Depth: number; Circumference: number; Id?: number };

@autoinject
export class NetCircumferenceDepthProductCreateEdit {
  @bindable
  @observable
  model: Model;
  targetModel: Model = { Circumference: 0, 0: { ProductId: 0 }, 20: { ProductId: 0 } };
  modelChanged(model: Model) {
    this.targetModel = JSON.parse(JSON.stringify(model));

    this.form.Circumference = this.targetModel.Circumference;
    this.form.From0ProductId = this.targetModel[0].ProductId;
    this.form.From20ProductId = this.targetModel[20].ProductId;
  }

  @bindable onSave: (data: data) => void;
  @bindable onCanceled: () => void;

  @bindable
  @observable
  visible = false;

  protected form = {
    Circumference: 0,
    From0ProductId: undefined,
    From20ProductId: undefined,
  };

  constructor(
    private errorService: ErrorService,
    private validationController: ValidationController,
    private validator: Validator,
    private t: I18N
  ) {}

  private createModel(depth: number) {
    const source = this.targetModel[depth];
    const model = {
      ProductId: this.form[`From${depth}ProductId`],
      Depth: depth,
      Circumference: this.form.Circumference,
    };

    // If Id is set, it's an update
    if (source.Id) {
      model['Id'] = source.Id;
    }

    return model as MatrixSaveModel;
  }

  protected applyValidationRules() {
    ValidationRules.ensure('Circumference')
      .required()
      .withMessage(this.t.tr('general.requiredField'))
      .min(0)
      .withMessage(this.t.tr('validation.mustBeGreaterOrEqualToValue', { value: 0 }))
      .ensure('From0ProductId')
      .required()
      .withMessage(this.t.tr('general.requiredField'))
      // Requires min 1 because unset value is 0
      .min(1)
      .withMessage(this.t.tr('general.requiredField'))
      .ensure('From20ProductId')
      .required()
      .withMessage(this.t.tr('general.requiredField'))
      // Requires min 1 because unset value is 0
      .min(1)
      .withMessage(this.t.tr('general.requiredField'))
      .on(this.form);
  }

  attached() {
    this.applyValidationRules();
  }

  private async validate() {
    this.applyValidationRules();
    await this.validator.validateObject(this.form);
    return (await this.validationController.validate({ object: this.form })).valid;
  }

  async save() {
    try {
      const valid = await this.validate();
      if (!valid) return;
      this.onSave?.({
        data: [this.createModel(0), this.createModel(20)],
      });
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  cancel() {
    this.onCanceled?.();
  }

  protected async setProductId(depth: number, data: { value: number | string }) {
    this.form[`From${depth}ProductId`] = +data.value;
    await this.validate();
  }
}
