import { autoinject, bindable, observable } from 'aurelia-framework';
import { Models } from 'models/core';
import { DetergentTypeService } from 'services/detergent-type-service';
import { DisinfectionTypeService } from 'services/disinfection-type-service';
import { ErrorService } from 'services/error-service';
import { ServiceConsumptionWashingService } from 'services/service-consumption-washing-service';

type Model = {
  DetergentTypeId: number;
  DetergentConsumption: number;
  DisinfectionTypeId: number;
  DisinfectionConsumption: number;
  Id?: number;
};

type data = { data: ServiceConsumptionWashingModel };

export type ServiceConsumptionWashingModel = {
  ServiceId: number;
  DetergentTypeId: number;
  DetergentConsumption: number;
  DisinfectionTypeId: number;
  DisinfectionConsumption: number;
  Id?: number;
};

@autoinject
export class ServiceWashingConsumptionCreateEdit {
  @bindable
  @observable
  model: Model;
  targetModel: Model = {
    DetergentTypeId: 0,
    DetergentConsumption: undefined,
    DisinfectionTypeId: 0,
    DisinfectionConsumption: undefined,
  };
  modelChanged(model: Model) {
    this.targetModel = JSON.parse(JSON.stringify(model));

    this.form.DetergentTypeId = this.targetModel.DetergentTypeId;
    this.form.DetergentConsumption = this.targetModel.DetergentConsumption;
    this.form.DisinfectionTypeId = this.targetModel.DisinfectionTypeId;
    this.form.DisinfectionConsumption = this.targetModel.DisinfectionConsumption;
  }

  @bindable service: Models.Service;

  @bindable onSave: (data: data) => void;
  @bindable onCanceled: () => void;

  @bindable
  @observable
  visible = false;

  protected form = {
    DetergentTypeId: undefined,
    DetergentConsumption: undefined,
    DisinfectionTypeId: undefined,
    DisinfectionConsumption: undefined,
  };

  constructor(
    private errorService: ErrorService,
    protected serviceConsumptionWashingService: ServiceConsumptionWashingService,
    protected detergentTypeService: DetergentTypeService,
    protected disinfectionTypeService: DisinfectionTypeService
  ) {}

  protected async getDetergentTypes() {
    const detergentTypes = await this.detergentTypeService.getAll('?$expand=Product');

    detergentTypes.forEach((detergentType) => {
      if(detergentType.Product){
        detergentType.Name = detergentType.Name + " (" + detergentType.Product.ArticleNo + ")";
        detergentType.NameEn = detergentType.NameEn + " (" + detergentType.Product.ArticleNo + ")";
        detergentType.NameEs = detergentType.NameEs + " (" + detergentType.Product.ArticleNo + ")";
      }
    })
    
    if (this.service.IsInvoicingCompatible) {
      return detergentTypes.filter((x) => !isNaN(Number.parseInt(`${x.ProductId}`)));
    }
    return detergentTypes;
  }
  protected async getDisinfectionTypes() {
    const disinfectionTypes = await this.disinfectionTypeService.getAll('?$expand=Product');

    disinfectionTypes.forEach((disinfectionType) => {
      if(disinfectionType.Product){
        disinfectionType.Name = disinfectionType.Name + " (" + disinfectionType.Product.ArticleNo + ")";
        disinfectionType.NameEn = disinfectionType.NameEn + " (" + disinfectionType.Product.ArticleNo + ")";
        disinfectionType.NameEs = disinfectionType.NameEs + " (" + disinfectionType.Product.ArticleNo + ")";
      }
    })

    if (this.service.IsInvoicingCompatible) {
      return disinfectionTypes.filter((x) => !isNaN(Number.parseInt(`${x.ProductId}`)));
    }
    return disinfectionTypes;
  }

  private createModel() {
    const source = this.targetModel;
    const model = {
      ServiceId: this.service.Id,
      DetergentTypeId: this.form.DetergentTypeId,
      DetergentConsumption: this.form.DetergentConsumption,
      DisinfectionTypeId: this.form.DisinfectionTypeId,
      DisinfectionConsumption: this.form.DisinfectionConsumption,
    };

    if (source.Id) {
      model['Id'] = source.Id;
    }

    return model as ServiceConsumptionWashingModel;
  }

  save() {
    try {
      this.onSave?.({
        data: this.createModel(),
      });
      this.resetForm();
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  resetForm() {
    this.form.DetergentTypeId = 0;
    this.form.DetergentConsumption = undefined;
    this.form.DisinfectionTypeId = 0;
    this.form.DisinfectionConsumption = undefined;
  }

  cancel() {
    this.onCanceled?.();
  }
}
