import { autoinject, bindable, observable } from 'aurelia-framework';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { ValidationController, ValidationRules, Validator } from 'aurelia-validation';
import { I18N } from 'aurelia-i18n';
import { ServiceInvoiceStandardProduct } from 'models';

@autoinject
export class ServiceInvoiceStandardProductCreateEdit {
  @bindable
  @observable
  model: ServiceInvoiceStandardProduct | undefined = undefined;

  targetModel: ServiceInvoiceStandardProduct = {
    ProductId: undefined,
    Id: undefined,
    IsForSpecialProduct: false,
  };

  @bindable
  protected specialProductTypes: Models.SpecialProductType[] = [];

  modelChanged(model: ServiceInvoiceStandardProduct) {
    this.targetModel = JSON.parse(JSON.stringify(model));
    this.form.Id = this.targetModel?.Id;
    this.form.ProductId = this.targetModel.ProductId;
    this.form.IsForSpecialProduct = this.targetModel.IsForSpecialProduct ?? false;
  }

  @bindable onSave: (data: { data: ServiceInvoiceStandardProduct }) => void;
  @bindable onCanceled: () => void;

  @bindable
  visible = false;

  protected form: ServiceInvoiceStandardProduct = {
    ProductId: undefined,
    IsForSpecialProduct: false,
    Id: undefined,
  };

  constructor(
    private errorService: ErrorService,
    private validationController: ValidationController,
    private validator: Validator,
    private t: I18N
  ) {}

  protected applyValidationRules() {
    ValidationRules.ensure('ProductId')
      .required()
      .withMessage(this.t.tr('general.requiredField'))
      .min(1)
      .withMessage(this.t.tr('general.requiredField'))
      .on(this.form);
  }

  attached() {
    this.applyValidationRules();
  }

  private async validate() {
    this.applyValidationRules();
    await this.validator.validateObject(this.form);
    return (await this.validationController.validate({ object: this.form })).valid;
  }

  async save() {
    try {
      if (!(await this.validate())) return;
      this.onSave?.({ data: this.form });
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  cancel() {
    this.onCanceled?.();
  }

  protected async setIsForSpecialProduct(event: Event) {
    const target = event.target as HTMLInputElement;
    this.form.IsForSpecialProduct = target.checked;
    await this.validate();
  }

  protected async setProductId(data: { value: number | string }) {
    this.form.ProductId = +data?.value as number;
    await this.validate();
  }
}
